import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BannerWordpress from '../component/banner/BannerWordpress';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import ProcessGrowth from '../component/process/ProcessGrowth';
import BrandOne from '../component/brand/BrandOne';
import AboutGrowth from '../component/about/AboutGrowth';
import AboutOne from '../component/about/AboutOne';
import BlogOne from '../component/blog/BlogOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import WhyChooseGrowth from '../component/whychoose/WhyChooseGrowth';
import ProjectOne from '../component/project/ProjectOne'
import WhyChooseWordpress from '../component/whychoose/WhyChooseWordpress';
import { Helmet } from 'react-helmet';

const WordPress = () => {

    return (
        <>
        <SEO title="Plataforma Ecommerce Wordpress en Argentina" />

        <Helmet>
        <meta name="description" content=" En Merco somos expertos en el diseño de Plataformas Ecommerce Wordpress en Argentina. Diseñamos sitios webs de ecommerce que se ajusten a tu negocio. " />
        </Helmet>
        
        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BannerWordpress />
                <WhyChooseWordpress />
                <BrandOne />
                <AboutOne />
                <BlogOne />
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default WordPress;