import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import BrandItem from './BrandItem';


const BrandOne = () => {
    return (
        <div className="section section-padding-2">
        <div className="container">
            <SectionTitle 
                subtitle="Partnership"
                title="Codo a codo con nuestros partners."
                description="Contamos con el apoyo de las más grandes plataformas de anuncios en todo internet."
                textAlignment="heading-left"
                textColor=""
            />
            <div className='row'>
               <BrandItem />
            </div>
        </div>
        <ul className="list-unstyled shape-group-10">

        </ul>
    </div>
    )
}

export default BrandOne;