import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import SectionTitle from '../../elements/section-title/SectionTitle';
import { FaCompress, FaCode, FaGlobe, FaBrush } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll-v2'

const WhyChooseIG = () => {
    return (
      <div className="section-padding bg-color-light">
        <div className="container">
            <div className="row">
            <div className="col-lg-6">
                <div className="why-choose-us">
                <SectionTitle 
                    subtitle="Publicidad en Instagram"
                    title="Estas listo para empezar en Instagram?"
                    description=""
                    textAlignment="heading-left"
                    textColor=""
                />
                    <Accordion defaultActiveKey="1">
                        <Accordion.Item eventKey="1">
                            <Accordion.Header><FaCompress /> Optimizaciones personalizadas</Accordion.Header>
                            <Accordion.Body>
                            La publicidad en Facebook se maneja con un modelo de precios de CPC, CPM o CPA. Nuestras optimizaciones apuntan a que el costo por adquisición o venta sea siempre el menor posible con tus anuncios.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header><FaCode /> Pagás sólo cuando hay resultados</Accordion.Header>
                            <Accordion.Body>
                            El pago se efectúa solamente cuando el público o clientes potenciales interactúa o realiza la acción deseada: compra, consulta, se suscribe o reproduce los videos, entre tantos otros objetivos que te puedas proponer con una audiencia.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header><FaGlobe /> Geo-segmentación</Accordion.Header>
                            <Accordion.Body>
                            Podés segmentar geográficamente tu público hasta 1 km. a la redonda del punto que indiques en el mapa. Esto significa que podemos crear una campaña o anuncios para que lleguen a las personas que realmente pueden convertirse en clientes.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header><FaBrush /> Personalización total</Accordion.Header>
                            <Accordion.Body>
                            Publicitando en Facebook Ads, podés elegir por donde querés lanzar tu campaña, por ejemplo: stories, feed, MarketPlace, sitios asociados, entre otras múltiples opciones. Adaptamos los contenidos a cada formato para crear anuncios.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    
                </div>
            </div>
            <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="why-choose-us mb--30">
                <div className="why-choose-thumb">
                    <img src={process.env.PUBLIC_URL + "/images/about/about-3-instagram.png"} alt="Office" />
                </div>
                </div>
            </div>
            </div>


            <div className="row">
            <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="why-choose-us mb--30">
                <div className="why-choose-thumb">
                    <img src={process.env.PUBLIC_URL + "/images/about/about-4-instagram.png"} alt="Office" />
                </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="why-choose-us">
                    
                <SectionTitle 
                    subtitle=""
                    title="Campañas Optimizadas"
                    description=""
                    textAlignment="heading-left"
                    textColor=""
                />
                  <p>
                    Contamos con un servicio de mantenimiento y optimización continuo de las Campañas de Facebook Ads (los anuncios en Facebook) que te permitirán mejorar el rendimiento y enfocarnos en cumplir objetivos establecidos en el momento CERO.
                  </p>
                  <p>
                    En ese sentido, nuestro objetivo es que tu Campaña esté siempre actualizada (ya que todo mercado fluctúa) para que puedas optimizar el uso de tus recursos generando mejores resultados y reconocimiento de marca.
                  </p>

                  <AnchorLink href="#contact" className="axil-btn btn-large btn-fill-white">Empecemos</AnchorLink>
                </div>
            </div>
            
            </div>
            
        </div>
        </div>

    )
}

export default WhyChooseIG;