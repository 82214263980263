import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll-v2';


const BannerIG = () => {
    return (
        <div className="banner banner-style-ig">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="banner-content">
                            
                            <h2 className="title"> Publicidad en </h2>                  
                            <div className='lol'>
                                <img src={process.env.PUBLIC_URL + "/images/icon/Instagram.svg"}  className="facebook" />
                            </div>
                            <span className="subtitle">Creamos campañas en Facebook ads enfocadas en performance con estrategias que permiten obtener mayor visibilidad e impacto en las audiencias logrando resultados realmente medibles. </span>
                            <AnchorLink href="#contact" className="axil-btn btn-large btn-fill-white">Empecemos</AnchorLink>
                        </div>
                        
                    </div>
                </div>
            </div>
            
            <ul className="list-unstyled shape-group-18">
            <li className="shape shape-2">

                    <img src={process.env.PUBLIC_URL + "/images/banner/banner-shape-2.png"} alt="Shape" />

                </li>
                <li className="shape shape-1">
                <AnimationOnScroll  animateIn="slideInRight" duration={1} animateOnce={true} delay={100}>
                    <img src={process.env.PUBLIC_URL + "/images/banner/banner-shape-1.png"} alt="Shape" />
                </AnimationOnScroll>
                </li>
                <li className="shape shape-11">
                <AnimationOnScroll  animateIn="slideInRight" duration={1} animateOnce={true} delay={100}>
                    <img src={process.env.PUBLIC_URL + "/images/banner/banner-thumb-7.png"} alt="Shape" className='hidemobile' />
                </AnimationOnScroll>
                </li>

                <li className="shape shape-3">
                <AnimationOnScroll  animateIn="zoomIn" duration={1} animateOnce={true} delay={100}>
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-16.png"} alt="Shape" />
                </AnimationOnScroll>
                </li>
                <li className="shape shape-4">
                <AnimationOnScroll  animateIn="zoomIn" duration={1} animateOnce={true} delay={100}>
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-15.png"} alt="Shape" />
                </AnimationOnScroll>
                </li>
                <li className="shape shape-5">
                <AnimationOnScroll  animateIn="zoomIn" duration={1} animateOnce={true} delay={100}>
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Shape" />
                </AnimationOnScroll>
                </li>
                <li className="shape shape-6">
                    <AnimationOnScroll  animateIn="zoomIn" duration={1} animateOnce={true} delay={100}>
                        <img src={process.env.PUBLIC_URL + "/images/others/bubble-16.png"} alt="Shape" />
                    </AnimationOnScroll>
                </li>
                <li className="shape shape-7">
                    <AnimationOnScroll  animateIn="slideInDown" duration={1} animateOnce={true} delay={100}>
                        <img src={process.env.PUBLIC_URL + "/images/others/bubble-26.png"} alt="Shape" />
                    </AnimationOnScroll>
                </li>
            </ul>
        </div>
    )
}

export default BannerIG;