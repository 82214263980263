import React from 'react';
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll-v2'
import { FaAngleDown } from "react-icons/fa";


const Nav = () => {
    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
                <li className="menu-item-has-children">
                    <Link to="#">Publicidad Online <FaAngleDown /> </Link>
                    <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/growth-performance"}>Growth Performance</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/publicidad-en-google-ads"}>Publicidad en Google Ads</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/publicidad-en-facebook"}>Publicidad en Facebook</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/publicidad-en-instagram"}>Publicidad en Instagram</Link></li>
                    </ul>
                </li>
                <li className="menu-item-has-children">
                    <Link to="#">Posicionamiento Web <FaAngleDown /></Link>
                    <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/seo"}>Posicionamiento SEO</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/posicionamiento-en-google"}>Posicionamiento en Google</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/reputacion-online"}>Reputacion Online</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/community-manager"}>Community Manager</Link></li>

                    </ul>
                </li>
                <li className="menu-item-has-children">
                    <Link to="#">E-commerce <FaAngleDown /></Link>
                    <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/plataformas-ecommerce"}>Plataformas e-Commerce</Link></li>
                        {<li><Link to={process.env.PUBLIC_URL + "/marketing-para-ecommerce"}>Marketing para e-Commerce</Link></li>}
                    </ul>
                </li>
                <li className="menu-item-has-children">
                    <Link to="#">Diseño Web <FaAngleDown /></Link>
                    <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/wordpress"}>Wordpress</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/sitio-institucional"}>Sitio Institucional</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/landing-page"}>Landing Page</Link></li>
                    </ul>
                </li>

                <li>
                   <Link to={process.env.PUBLIC_URL + "/nosotros"}>Nosotros</Link>
                </li>
            </ul>
        </nav>
    )
}

export default Nav;