import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BannerPlatEcommerce from '../component/banner/BannerPlatEcommerce';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import ProcessGrowth from '../component/process/ProcessGrowth';
import BrandOne from '../component/brand/BrandOne';
import AboutGrowth from '../component/about/AboutGrowth';
import AboutOne from '../component/about/AboutOne';
import BlogOne from '../component/blog/BlogOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import WhyChooseGrowth from '../component/whychoose/WhyChooseGrowth';
import ProjectOne from '../component/project/ProjectOne'
import WhyChoosePlatEcommerce from '../component/whychoose/WhyChoosePlatEcommerce';

const AboutUs = () => {

    return (
        <>
        <SEO title="Plataformas Ecommerce " />
        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BannerPlatEcommerce />
                <ProjectOne />
                <WhyChoosePlatEcommerce />
                <BrandOne />
                <AboutOne />
                <BlogOne />
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default AboutUs;