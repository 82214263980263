import React from 'react';
import FormOne from '../contact/FormOne';


const AboutOne = () => {
    return (

        <section id='contact' className="section section-padding-equal bg-color-light">
            <a name="contacto"></a>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="about-us">
                            <div className="section-heading heading-left mb-0">

                                <img className="hidemobile" src={process.env.PUBLIC_URL + "/images/others/contact.svg"} />


                            </div>
                        </div>
                    </div>

                    <div className="col-xl-5 col-lg-6 offset-xl-1">
                        <div className="contact-form-box">
                            <h5 className="subtitle"> ¿Quieres potenciar tu proyecto?</h5>

                            <a href='https://meetings.hubspot.com/fernando-velozo/rotacion?uuid=b09ca37e-1ea4-4d28-ad0c-d913b48ac5d4' className='contact-hubspot'>CONTACTANOS</a>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default AboutOne;