import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BannerGrowth from '../component/banner/BannerGrowth';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import ProcessGrowth from '../component/process/ProcessGrowth';
import ServiceProp from '../component/service/ServiceProp';
import BrandOne from '../component/brand/BrandOne';
import AboutGrowth from '../component/about/AboutGrowth';
import AboutOne from '../component/about/AboutOne';
import BlogOne from '../component/blog/BlogOne';
import FaqGrowth from '../component/faq/FaqGrowth'
import SectionTitle from '../elements/section-title/SectionTitle';
import WhyChooseGrowth from '../component/whychoose/WhyChooseGrowth';


const AboutUs = () => {

    return (
        <>
        <SEO title="Growth Performance" />
        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BannerGrowth />
                <WhyChooseGrowth/>
                <ProcessGrowth />
                <BrandOne />
                <AboutOne />
                <BlogOne />
                <FaqGrowth />
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default AboutUs;