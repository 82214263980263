import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import ScrollToTop from './component/scrollToTop/ScrollToTop';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Helmet} from 'react-helmet';


// Home Pages Import
import DigitalAgency from './pages/DigitalAgency';
import GrowthPerformance from './pages/GrowthPerformance';
import PersonalPortfolio from './pages/PersonalPortfolio';
import HomeStartup from './pages/HomeStartup';
import CorporateAgency from './pages/CorporateAgency';

// Blog Import
import BlogGridView from './pages/BlogGrid';
import BlogCategory from './pages/Category';
import BlogArchive from './pages/Archive';
import BlogDetails from './pages/BlogDetails';

// Service 
import PosicionamientoSEO from './pages/PosicionamientoSEO';
import PosicionamientoGoogle from './pages/PosicionamientoGoogle';
import ReputacionOnline from './pages/ReputacionOnline';
import CommunityManager from './pages/CommunityManager';


// Project 
import ProjectGridOne from './pages/ProjectGridOne';
import ProjectGridTwo from './pages/ProjectGridTwo';
import ProjectGridThree from './pages/ProjectGridThree';
import ProjectGridFive from './pages/ProjectGridFive';
import ProjectGridFour from './pages/ProjectGridFour';
import ProjectDetails from './pages/ProjectDetails';

// Pages 
import Splash from './pages/Splash';
import AboutUs from './pages/AboutUs';
import Nosotros from './pages/Nosotros';
import OurOffice from './pages/OurOffice';
import OurClients from './pages/OurClients';
import Team from './pages/Team';
import TeamDetails from './pages/TeamDetails';
import CaseStudy from './pages/CaseStudy';
import CaseDetails from './pages/CaseStudyDetails';
import Testimonials from './pages/Testimonials';
import PricingTable from './pages/PricingTable';
import Typography from './pages/Typography';
import Contact from './pages/Contact';
import ErrorPage from './pages/404';
import Gracias from './pages/Gracias'
import ComingSoon from './pages/ComingSoon';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import PlatEcommerce from './pages/PlatEcommerce';
import MktEcommerce from './pages/MktEcommerce';
import WordPress from './pages/WordPress';
import SitioInstitucional from './pages/SitioInstitucional';
import LandingPage from './pages/LandingPage'

// Css Import
import './assets/scss/app.scss';

window.scroll({
	top: 2500, 
	left: 0, 
	behavior: 'smooth'
  });

const App = () => {
  return (
    <Router>
		<ScrollToTop>
			<Routes>
				<Route path={process.env.PUBLIC_URL + "/"} element={<DigitalAgency />}/>

				{/* PUBLICIDAD ONLINE */}
				<Route path={process.env.PUBLIC_URL + "/growth-performance"} element={<GrowthPerformance />}/>
				<Route path={process.env.PUBLIC_URL + "/publicidad-en-google-ads"} element={<AboutUs />}/>
				<Route path={process.env.PUBLIC_URL + "/publicidad-en-facebook"} element={<PersonalPortfolio />}/>
				<Route path={process.env.PUBLIC_URL + "/publicidad-en-instagram"} element={<HomeStartup />}/>
	

				{/* POSICIONAMIENTO WEB */}
				<Route path={process.env.PUBLIC_URL + "/seo/"} element={<PosicionamientoSEO />}/>
				<Route path={process.env.PUBLIC_URL + "/posicionamiento-en-google/"} element={<PosicionamientoGoogle />}/>
				<Route path={process.env.PUBLIC_URL + "/reputacion-online"} element={<ReputacionOnline />}/>
				<Route path={process.env.PUBLIC_URL + "/community-manager"} element={<CommunityManager />}/>


				{/* E-Commerce */}
				<Route path={process.env.PUBLIC_URL + "/plataformas-ecommerce"} element={<PlatEcommerce />}/>
				<Route path={process.env.PUBLIC_URL + "/marketing-para-ecommerce"} element={<MktEcommerce />}/>

				<Route path={process.env.PUBLIC_URL + "/wordpress"} element={<WordPress />}/>
				<Route path={process.env.PUBLIC_URL + "/sitio-institucional"} element={<SitioInstitucional />}/>
				<Route path={process.env.PUBLIC_URL + "/landing-page"} element={<LandingPage />}/>


				<Route path={process.env.PUBLIC_URL + "/gracias"} element={<Gracias />}/>

				<Route path={process.env.PUBLIC_URL + "/nosotros"} element={<Nosotros />}/>



				<Route path={process.env.PUBLIC_URL + "/corporate-agency"} element={<CorporateAgency />}/>

				{/* Blogs */}
				<Route path={process.env.PUBLIC_URL + "/blog-grid/"} element={<BlogGridView />}/>
				<Route path={process.env.PUBLIC_URL + "/category/:slug"} element={<BlogCategory />}/>
				<Route path={process.env.PUBLIC_URL + "/archive/:slug"} element={<BlogArchive />}/>
				<Route path={process.env.PUBLIC_URL + "/blog-details/:id"} element={<BlogDetails />}/>

				{/* Service */}
				<Route path={process.env.PUBLIC_URL + "/service-one/"} element={<PosicionamientoSEO />}/>
				<Route path={process.env.PUBLIC_URL + "/posicionamiento-en-google/"} element={<PosicionamientoGoogle />}/>
				<Route path={process.env.PUBLIC_URL + "/service-details/:slug"} element={<ReputacionOnline />}/>

				{/* Project  */}
				<Route path={process.env.PUBLIC_URL + "/project-grid-one"} element={<ProjectGridOne />}/>
				<Route path={process.env.PUBLIC_URL + "/project-grid-two"} element={<ProjectGridTwo />}/>
				<Route path={process.env.PUBLIC_URL + "/project-grid-three"} element={<ProjectGridThree />}/>
				<Route path={process.env.PUBLIC_URL + "/project-width-one"} element={<ProjectGridFour />}/>
				<Route path={process.env.PUBLIC_URL + "/project-width-two"} element={<ProjectGridFive />}/>
				<Route path={process.env.PUBLIC_URL + "/project-details/:slug"} element={<ProjectDetails />}/>

				{/* Pages  */}
				<Route path={process.env.PUBLIC_URL + "/about-us"} element={<AboutUs />}/>
				<Route path={process.env.PUBLIC_URL + "/our-office"} element={<OurOffice />}/>
				<Route path={process.env.PUBLIC_URL + "/our-clients"} element={<OurClients />}/>
				<Route path={process.env.PUBLIC_URL + "/team"} element={<Team />}/>
				<Route path={process.env.PUBLIC_URL + "/team-details/:slug"} element={<TeamDetails />}/>
				<Route path={process.env.PUBLIC_URL + "/case-study"} element={<CaseStudy />}/>
				<Route path={process.env.PUBLIC_URL + "/case-details/:slug"} element={<CaseDetails />}/>
				<Route path={process.env.PUBLIC_URL + "/testimonials"} element={<Testimonials />}/>
				<Route path={process.env.PUBLIC_URL + "/pricing-table"} element={<PricingTable />}/>
				<Route path={process.env.PUBLIC_URL + "/typography"} element={<Typography />}/>
				<Route path={process.env.PUBLIC_URL + "/contact"} element={<Contact />}/>
				<Route path={process.env.PUBLIC_URL + "/404"} element={<ErrorPage />}/>
				<Route path={process.env.PUBLIC_URL + "/coming-soon"} element={<ComingSoon />}/>
				<Route path={process.env.PUBLIC_URL + "/privacy-policy"} element={<PrivacyPolicy />}/>
				<Route path={process.env.PUBLIC_URL + "/terms-use"} element={<TermsOfUse />}/>

			</Routes>
		</ScrollToTop>
    </Router>
  )
}

export default App;
