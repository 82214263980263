import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import SectionTitle from '../../elements/section-title/SectionTitle';
import { FaCompress, FaCode, FaGlobe, FaBrush, FaArrowAltCircleUp, FaRProject, FaAddressBook } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll-v2'





const WhyChooseOne = () => {
    return (
      <div className="section-padding bg-color-light">
        <div className="container">
            <div className="row">
            <div className="col-lg-6">
                <div className="why-choose-us">
                <SectionTitle 
                    subtitle="Posicionamiento en Google"
                    title="Como empezamos..."
                    description="Nuestros 10 años de experiencia nos hicieron expertos en <strong> <em> posicionamiento en Google.  </em> </strong> Conocemos todos los “hacks” que hay que aplicar para aparecer entre los primeros resultados. Hacemos un relevamiento sobre tu situación actual: tráfico, calidad de los contenidos, opiniones de los usuarios, usabilidad en todos los dispositivos, experiencia de usuario, etc."
                    textAlignment="heading-left"
                    textColor=""
                />
                    <Accordion defaultActiveKey="1">
                        <Accordion.Item eventKey="1">
                            <Accordion.Header><FaCompress /> Optimizaciones ONpage
                            </Accordion.Header>
                            <Accordion.Body>
                            Trabajamos para mejorar tu web teniendo en cuenta los siguientes factores: códigos, tiempo de carga, redacción, calidad de contenidos, experiencia de usuario, entre otros.

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header><FaCode /> Optimizaciones OFFpage </Accordion.Header>
                            <Accordion.Body>
                          
                            Trabajamos para mejorar la calidad externa de tu sitio web, teniendo como foco los enlaces a tu sitio web desde otros sitios (backlinks), las palabras clave, los anuncios, las opiniones de los usuarios, entre otros.

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header><FaGlobe /> Monitoreo</Accordion.Header>
                            <Accordion.Body>
                            Agregaremos los resultados en un Dashboard para que puedas monitorizar y analizar los resultados de la optimización en tiempo real, y realizaremos propuestas activas de mejora para tu sitio web.
                            </Accordion.Body>
                        </Accordion.Item>
                       
                    </Accordion>
                    
                </div>
            </div>
            <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="why-choose-us mb--30">
                <div className="why-choose-thumb">
                    <img src={process.env.PUBLIC_URL + "/images/about/about-3-posicionamiento-google.png"} alt="Office" />
                </div>
                </div>
            </div>
            </div>


            <div className="row">
            <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="why-choose-us mb--30">
                <div className="why-choose-thumb">
                    <img src={process.env.PUBLIC_URL + "/images/about/about-4-posicionamiento-google.png"} alt="Office" />
                </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="why-choose-us">
                    
                <SectionTitle 
                    subtitle=""
                    title="...el siguiente nivel"
                    description=""
                    textAlignment="heading-left"
                    textColor=""
                    textSize="10pt"
                />
  <Accordion defaultActiveKey="1">
                        <Accordion.Item eventKey="1">
                            <Accordion.Header><FaCompress /> Palabras clave

                            </Accordion.Header>
                            <Accordion.Body>
                            Investigamos qué palabras utilizan los usuarios para buscar productos o servicios similares a los tuyos, y las ponemos en práctica.


                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header><FaCode /> Calidad de Contenido </Accordion.Header>
                            <Accordion.Body>
                          
                            Generamos contenidos relevantes que atraen, invitan a quedarse y responden exactamente a lo que el usuario quiere saber o está buscando.

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header><FaGlobe /> Responsive</Accordion.Header>
                            <Accordion.Body>
                            Diseñamos tu web para que se adapte a todas las plataformas y se vea bien en múltiples pantallas: ordenadores, móviles, o tablets.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header><FaAddressBook /> Reputación</Accordion.Header>
                            <Accordion.Body>
                            Cuántas más reseñas, valoraciones y comentarios positivos tengas, Google te premiará de mejor manera.
                            </Accordion.Body>
                        </Accordion.Item>
                       
                    </Accordion>

                        <AnchorLink href="#contact" className="axil-btn btn-large btn-fill-white" style={{marginTop: '50px'}}>Empecemos</AnchorLink>

                </div>
            </div>
            
            </div>
            
        </div>
        </div>

    )
}

export default WhyChooseOne;