import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import AnchorLink from 'react-anchor-link-smooth-scroll-v2';
import { Link } from 'react-router-dom';


const Datas = [
    {
        id: 1,
        title: "Análisis e Históricos:",
        para: "Analizamos datos históricos de hace un año para todas las fuentes de medios (Orgánico, Ads, Mailing, Directo, Display, Search). Es decir, el estudio de cada fuente tiene una media de 6 meses a 1 año y de esta forma podemos conocer el comportamiento de cada una de ellas a lo largo del tiempo."
    },
    {
        id: 2,
        title: "Establecer objetivos:",
        para: "Relación, facturación, ROAS. Inversión/Facturación. En base a ellos analizamos la accionabilidad de cada canal o fuente para alcanzar los objetivos establecidos."
    },
    {
        id: 3,
        title: "Proyección:",
        para: "Hacemos una proyección teniendo en cuenta diferentes factores: Inversión en medios + Fuentes de medios + Objetivos de facturación. De esta forma podremos saber lo alcanzable o realizable de los objetivos, siempre TENIENDO EN CUENTA el histórico del proyecto. Proponiendo un CRECIMIENTO BASADO EN LA HISTORIA."
    },
    {
        id: 4,
        title: "Planificacion Vs Real:",
        para: "Realizamos el seguimiento de los siguientes KPI: Visitas, Facturación, Ticket medio, Tasa de conversión, Artículos por compra, Inversión y Ratio de inversión en facturación. Con variaciones diarias y semanales en función de las estimaciones. Así podemos ver hasta qué punto estamos cumpliendo el objetivo. "
    },
    {
        id: 5,
        title: "Reportes",
        para: "Informe semanal + quincenal + mensual (realizando una comparación de la evolución de los resultados en tiempo real): Dashboards en Data Studio."
    },
    {
        id: 6,
        title: "Corrección y reacción",
        para: "(Todos para uno y uno para todos): En base a lo sucedido planificamos accionables que permitan corregir los Kpis para reaccionar en el mismo mes y tomar decisiones que nos permitan estar lo más cerca posible de lo planificado."
    }
]




const AboutThree = () => {
    return (
        <div className="section section-padding bg-color-blue pb--80 pb_lg--40 pb_md--20">
            <div className="container">
                <SectionTitle 
                    subtitle=""
                    title="¿Sabes cuáles son los Pilares del Growth Marketing?"
                    description="El proceso de trabajo de Growth está basado en 6 Pilares: ¿Estás listo para alcanzar los primeros resultados?"
                    textAlignment="heading-left heading-light-left mb--100"
                    textColor=""
                />

                <div className="row">
                    {Datas.map((data) => (
                        <div className="col-lg-4" key={data.id}>
                            <div className="about-quality">
                                <h3 className="sl-number">{data.id}</h3>
                                <h5 className="title">{data.title}</h5>
                                <p>{data.para}</p>
                            </div>
                        </div>
                    ))}
                    
                    <div className="section-heading heading-light">

                        <AnchorLink  href='#video' className="axil-btn btn-large btn-fill-white">
                            Continuar
                        </AnchorLink>
                    </div>
                </div>
            </div>


            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1 hidemobile"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>

            </ul>
        </div>
    )
}

export default AboutThree;