import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import SectionTitle from '../../elements/section-title/SectionTitle';
import { FaCompress, FaCode, FaGlobe, FaBrush, FaArrowAltCircleUp, FaRProject, FaAddressBook } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll-v2'

const WhyChooseOne = () => {
    return (
      <div className="section-padding bg-color-light">
        <div className="container">
            <div className="row">
            <div className="col-lg-6">
                <div className="why-choose-us">
                <SectionTitle 
                    subtitle="Posicionamiento en Google"
                    title="Como empezamos..."
                    description="Para consolidar la imagen de tu marca, primero tenemos que conocer su historia, sus valores, lo que hay para mejorar y su público destinatario. Analizaremos la imagen de tu marca a través de la opinión de los usuarios en comentarios, reseñas, mensajes y búsquedas en Google."
                    textAlignment="heading-left"
                    textColor=""
                />
                    <Accordion defaultActiveKey="1">
                        <Accordion.Item eventKey="1">
                            <Accordion.Header><FaCompress /> Estrategia
                            </Accordion.Header>
                            <Accordion.Body>
                            Comenzaremos a mejorar tu imagen a través de una comunicación transparente, cercana, eficaz y amable para causar un buen impacto en tus seguidores.

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header><FaCode /> Relanzamiento </Accordion.Header>
                            <Accordion.Body>
                          
 
Trabajaremos en mejorar tus diseños y creatividades, testearemos la usabilidad de tu página web y la experiencia de usuario.

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header><FaGlobe /> Evaluación</Accordion.Header>
                            <Accordion.Body>
                            Al analizar las métricas a fin de mes, descubriremos si las estrategias implementadas funcionaron y elevaron la confianza de los usuarios en ti.

                            </Accordion.Body>
                        </Accordion.Item>
                       
                    </Accordion>
                    
                </div>
            </div>
            <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="why-choose-us mb--30">
                <div className="why-choose-thumb">
                    <img src={process.env.PUBLIC_URL + "/images/about/about-3-reputacion-online.png"} alt="Office" />
                </div>
                </div>
            </div>
            </div>


            <div className="row">
            <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="why-choose-us mb--30">
                <div className="why-choose-thumb">
                    <img src={process.env.PUBLIC_URL + "/images/about/about-4-reputacion-online.png"} alt="Office" />
                </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="why-choose-us">
                    
                <SectionTitle 
                    subtitle=""
                    title="¿Por qué es importante
                    mejorar tu reputación
                    online?"
                    description=""
                    textAlignment="heading-left"
                    textColor=""
                    textSize="10pt"
                />
  <Accordion defaultActiveKey="1">
                        <Accordion.Item eventKey="1">
                            <Accordion.Header><FaCompress /> 
                            Los consumidores son cada vez más exigentes.

                            </Accordion.Header>
                            <Accordion.Body>
                            Los usuarios realizan una investigación previa en internet antes de
realizar una compra: comparan productos, leen opiniones y consultan
con otros usuarios.


                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header><FaCode />El SEO tiene en cuenta la reputación. </Accordion.Header>
                            <Accordion.Body>
                          
                            Google tiene en cuenta las valoraciones positivas y la cantidad de tráfico a la página web para posicionar un sitio entre los primeros resultados.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header><FaGlobe /> Porque influye en nuestro prestigio e imagen de marca.
</Accordion.Header>
                            <Accordion.Body>
                            Los usuarios confían más en la opinión de otras personas que en las promesas de la marca, por lo que tener una buena reputación es indispensable.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header><FaAddressBook /> Porque se relaciona con la fidelización.
</Accordion.Header>
                            <Accordion.Body>
                            Para que un usuario se convierta en cliente fiel, e incluso le recomiende tu marca a otras personas, debe confiar en ti.
                            </Accordion.Body>
                        </Accordion.Item>


                        <Accordion.Item eventKey="5">
                            <Accordion.Header><FaAddressBook /> Porque incrementa las ventas.

</Accordion.Header>
                            <Accordion.Body>
                            Una marca con buena prensa y clientes satisfechos generará más confianza y por lo tanto más ventas, las cuales se sostendrán a través del tiempo.
                            </Accordion.Body>
                        </Accordion.Item>
                       
                    </Accordion>

                        <AnchorLink href="#contact" className="axil-btn btn-large btn-fill-white" style={{marginTop: '50px'}}>Empecemos</AnchorLink>

                </div>
            </div>
            
            </div>
            
        </div>
        </div>

    )
}

export default WhyChooseOne;