import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import SectionTitle from '../../elements/section-title/SectionTitle';
import { FaCompress, FaCode, FaGlobe, FaBrush, FaArrowAltCircleUp, FaRProject, FaAddressBook } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll-v2'

const WhyChooseOne = () => {
    return (
      <div className="section-padding bg-color-light">
        <div className="container">


            <div className="row" style={{marginTop:'100px'}} >
            <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="why-choose-us mb--30">
                <div className="why-choose-thumb">
                    <img src={process.env.PUBLIC_URL + "/images/about/about-3-landings.png"} alt="Office" />
                </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="why-choose-us">
                    
                <SectionTitle 
                    subtitle=""
                    title="
                    Marketing directo

                    
                    "
                    description="
                    Su objetivo es guiar a cada usuario que ingresa a que realice una
                    acción determinada, como completar un formulario de contacto,
                    aumentando así las conversiones o consultas a través del mailing,
                    chatbots o WhatsApp.
                    
                    "
                    textAlignment="heading-left"
                    textColor=""
                    textSize="10pt"
                />
  <Accordion defaultActiveKey="1">
                        <Accordion.Item eventKey="1">
                            <Accordion.Header><FaCompress />¡Potenciamos la imagen de tu marca!


                            </Accordion.Header>
                            <Accordion.Body>
                            La primera impresión es clave. Una landing page con diseño elegante, moderno y sencillo será el primer paso para ganarnos la atención del usuario. Luego a través de calls to action lo guiaremos a realizar la acción deseada.



                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header><FaCode />Diseños impactantes para lograr más conversiones

 </Accordion.Header>
                            <Accordion.Body>
                            Nuestros profesionales están especializados en Marketing, y ese es nuestro diferencial: no solo pensamos diseños visualmente estéticos, sino que utilizamos herramientas de comunicación persuasiva para generar más ventas.

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header><FaGlobe /> Impulsamos campañas de promociones directas
</Accordion.Header>
                            <Accordion.Body>
                            Ajustá las promociones y las comunicaciones de marketing directo según las necesidades de cada usuario en particular. Conocé las preferencias de tus clientes y personalizá tus anuncios para ofrecerle exactamente lo que necesitan.                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header><FaAddressBook /> Captamos potenciales clientes y los fidelizamos a la marca </Accordion.Header>
                            <Accordion.Body>
                            Incrementá tu índice de conversiones: invitá a los usuarios a suscribirse a tu newsletter, ofreceles promociones especiales, incentivalos a realizar una consulta. Todo esto aumentará tus chances de que se convierta en cliente.                            </Accordion.Body>
                        </Accordion.Item>


                       
                    </Accordion>

                        <AnchorLink href="#contact" className="axil-btn btn-large btn-fill-white" style={{marginTop: '50px'}}>Empecemos</AnchorLink>

                </div>
            </div>
            
            </div>
            
        </div>
        </div>

    )
}

export default WhyChooseOne;