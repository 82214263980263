import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import SectionTitle from '../../elements/section-title/SectionTitle';
import { FaCompress, FaCode, FaGlobe, FaBrush, FaArrowAltCircleUp, FaRProject, FaAddressBook } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll-v2'

const WhyChooseOne = () => {
    return (
      <div className="section-padding bg-color-light">
        <div className="container">
            <div className="row">
            <div className="col-lg-6">
                <div className="why-choose-us">
                <SectionTitle 
                    subtitle="Plataformas Ecommerce"
                    title="¿Qué incluye el servicio de
                    e-Commerce?"
                    description="No pierdas tiempo en cargar manualmente los productos, nuestra tecnología lo hace por vos. Es fundamental realizar las campañas smart que proponen las plataformas de pauta online ya que trabajan en base al comportamiento del usuario."
                    textAlignment="heading-left"
                    textColor=""
                />
                    <Accordion defaultActiveKey="1">
                        <Accordion.Item eventKey="1">
                            <Accordion.Header><FaCompress /> 
Carga productos
y modifica
precios en un
instante

                            </Accordion.Header>
                            <Accordion.Body>

                            Los productos y sus precios se importan y exportan desde planillas de Excel, y podrás modificarlos con un solo clic. Publica ilimitadamente y modifica precios, banners y productos cuantas veces quieras ¡El dominio es tuyo!

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header><FaCode /> Realiza Envíos a
cualquier parte
del mundo
 </Accordion.Header>
                            <Accordion.Body>
                          
 
                            Trabajamos con todos los servicios de logística: elige el que más te guste e imprime tu mismo las etiquetas de envío.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header><FaGlobe /> Integra todos
tus canales de
marketing
y venta
</Accordion.Header>
                            <Accordion.Body>

                            Integra tu tienda online con Instagram y Google Shopping. También podrás integrar apps exclusivas de tu negocio y todas tus redes sociales. Con un solo clic los usuarios de otras plataformas serán redireccionados a tu e-commerce.




                            </Accordion.Body>
                        </Accordion.Item>
                       
                    </Accordion>
                    
                </div>
            </div>
            <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="why-choose-us mb--30">
                <div className="why-choose-thumb">
                    <img src={process.env.PUBLIC_URL + "/images/about/about-3-ecommerce.png"} alt="Office" />
                </div>
                </div>
            </div>
            </div>

            <div className="row" style={{marginTop:"100px"}}>
            <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="why-choose-us mb--30">
                <div className="why-choose-thumb">
                <img src={process.env.PUBLIC_URL + "/images/about/about-4-ecommerce.png"} alt="thumbnail" />
                </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="why-choose-us">
                <SectionTitle 
                    subtitle=""
                    title="¡Confía en nosotros!"
                    description="En Merco tenemos 10 años de experiencia en el rubro y un servicio de soporte técnico totalmente capacitado para crear un e-commerce a tu medida, teniendo en cuenta todas las especificidades de tu negocio y modificándolo cuantas veces sea necesario para que quede perfecto."
                    textAlignment="heading-left"
                    textColor=""
                />
                                        <p>
                 
El éxito de nuestros clientes es lo que más nos importa, por eso nos aseguramos de crear un sitio web seguro y accesible para que tus clientes vuelvan una y otra vez. ¡Anímate a llevar tu negocio a otro nivel!

                        </p>
                        <AnchorLink href="#contact" className="axil-btn btn-large btn-fill-white">Empecemos</AnchorLink>
                    
                </div>
            </div>

            </div>

            
        </div>
        </div>

    )
}

export default WhyChooseOne;