import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import ProcesstData from "../../data/process/ProcessDataTwo.json";
import Tilty from 'react-tilty';

const getProcesstData = ProcesstData;

const ProcessOne = () => {
    return (
        
        <div className="section section-padding bg-color-light pb--70">
            <SectionTitle 
                subtitle=""
                title="Nuestra trayectoria..."
                description="Realizamos una auditoria donde analizamos el mercado objetivo y hacemos un diagnostico general de tu situacion actual para que podamos comenzar con los primeros pasos:"
                textAlignment=""
                textColor="mb--90"
            />
            <div className="container">
        <img src={process.env.PUBLIC_URL + "/images/others/pic1_timeline.png"} alt="" />
            </div>
            <ul className="shape-group-17 list-unstyled" style={{marginBottom: "100px"}}>
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-24.png"} alt="Bubble" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-23.png"} alt="Bubble" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="Line" /></li>
                <li className="shape shape-4"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="Line" /></li>
                <li className="shape shape-5"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="Line" /></li>
                <li className="shape shape-6"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="Line" /></li>
            </ul>


            <SectionTitle 
                subtitle="Nuestro Proceso"
                title="¿Cómo mejoramos las CAMPAÑAS DIGITALES?"
                description="Es importante tener en cuenta el ciclo de compra y el valor en el tiempo del cliente (Lifetime Value)"
                textAlignment=""
                textColor="mb--90"
            />
            <div className="container">
        <img src={process.env.PUBLIC_URL + "/images/others/pic2_process.png"} alt="" />
            </div>
            <ul className="shape-group-17 list-unstyled">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-24.png"} alt="Bubble" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-23.png"} alt="Bubble" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="Line" /></li>
                <li className="shape shape-4"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="Line" /></li>
                <li className="shape shape-5"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="Line" /></li>
                <li className="shape shape-6"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="Line" /></li>
            </ul>
        </div>

        
    )
}

export default ProcessOne;