import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import SectionTitle from '../../elements/section-title/SectionTitle';
import { FaCompress, FaCode, FaGlobe, FaBrush, FaArrowAltCircleUp } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll-v2'

const WhyChooseOne = () => {
    return (
      <div className="section-padding bg-color-light">
        <div className="container">
            <div className="row">
            <div className="col-lg-6">
                <div className="why-choose-us">
                <SectionTitle 
                    subtitle="Posicionamiento Web"
                    title="Ventajas del
                    Posicionamiento
                    SEO
                    "
                    description=""
                    textAlignment="heading-left"
                    textColor=""
                />
                    <Accordion defaultActiveKey="1">
                        <Accordion.Item eventKey="1">
                            <Accordion.Header><FaCompress /> Omnipresentes </Accordion.Header>
                            <Accordion.Body>
                            Posicionamiento las 24 Hs, los 365 días del año.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header><FaCode /> Precio</Accordion.Header>
                            <Accordion.Body>
                            No se paga a Google por clics recibidos, ALTO RENDIMIENTO de inversión (hoy un clic cuesta en google ads entre 20 a 30 pesos).
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header><FaGlobe /> Medición</Accordion.Header>
                            <Accordion.Body>
                            Se mide como asiste a las conversiones y además aquellas consultas que vienen 100% del canal orgánico.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header><FaBrush /> Posicionamiento</Accordion.Header>
                            <Accordion.Body>
                            Posicionamiento de contenidos relevantes para estar en TODOS los Micromomentos de nuestros potenciales clientes (lo acompañamos en TODAS las instancias del Funnel).
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header><FaArrowAltCircleUp /> Rendimiento</Accordion.Header>
                            <Accordion.Body>
                            Aumenta la visibilidad de la Marca y genera confianza. Optimizaciones en Tiempos de Carga para smartphones.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    
                </div>
            </div>
            <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="why-choose-us mb--30">
                <div className="why-choose-thumb">
                    <img src={process.env.PUBLIC_URL + "/images/about/about-3-posicionamiento-seo.png"} alt="Office" />
                </div>
                </div>
            </div>
            </div>


            <div className="row">
            <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="why-choose-us mb--30">
                <div className="why-choose-thumb">
                    <img src={process.env.PUBLIC_URL + "/images/about/about-4-posicionamiento-seo.png"} alt="Office" />
                </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="why-choose-us">
                    
                <SectionTitle 
                    subtitle=""
                    title="Micromomentos de los usuarios"
                    description=""
                    textAlignment="heading-left"
                    textColor=""
                    textSize="10pt"
                />
                  <p>
                  Dado el contexto pensamos en un plan que tenga en cuenta los “Micromomentos”. Se utiliza el blog o sección de novedades para posicionar temas de coyuntura actual y se trabaja para mantener dichas notas posicionadas actualizadas a los efectos de aparecer en búsquedas de los “micromomentos de nuestros usuarios”.

                  </p>
                  <p>
                  De esta forma empezamos a interactuar con nuestro público objetivo en instancias Upper Funnel. Si bien esta opción requiere de un trabajo constante para lograr un buen posicionamiento a mediano plazo, su principal ventaja es que no depende de inversiones, ni de horarios para que salga publicado en comparación a herramientas pagas de Google, cómo Google Ads (SEM).
                  </p>

                  <AnchorLink href="#contact" className="axil-btn btn-large btn-fill-white">Empecemos</AnchorLink>
                </div>
            </div>
            
            </div>
            
        </div>
        </div>

    )
}

export default WhyChooseOne;