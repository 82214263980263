import React from 'react';
import { FaLightbulb, FaMoon, FaEnvelope, FaWhatsapp, FaInstagram, FaYoutube, FaGlobe } from 'react-icons/fa';
import AnchorLink from 'react-anchor-link-smooth-scroll-v2'

const ColorSwitcher = () => {

    const switchColor = () => {
        document.querySelector('body').classList.toggle("active-dark-mode");
    }

    return (
        <div className="my_switcher d-none d-lg-block">
            <button onClick={switchColor}>  Cambiar Tema.....
                <span className="setColor hvr-grow"><FaLightbulb /></span>
            </button>

            <button><AnchorLink href='#contact'>Enviar Correo...</AnchorLink>
                <span className="correo"><FaEnvelope /></span>
            </button>
            <button><a Target="_blank" href='https://api.whatsapp.com/send?phone=5491131731534'> Enviar Whatsapp</a>
                <span className="wasap"><FaWhatsapp /></span>
            </button>

        </div>
    )
}

export default ColorSwitcher;