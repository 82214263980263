import React from 'react'
import PropTypes from "prop-types";


const SEO = ( {title} ) => {
    return (
        <>
            <meta charSet="utf-8" />
            <title>{title} | Merco Digital</title>
            <meta name="robots" content="follow" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <meta name="keywords" content="keyword 1, keyword 2, keyword 3"/>
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;