import React from 'react';
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll-v2'


const AboutFive = () => {
    return (
      <div className="section-padding-equal"  style={{padding:'140px 0 0 0' }} >
            <div className="container">
                <div className="row align-items-center">
                <div className="col-lg-7">
                    <div className="about-team">
                    <div className="thumbnail">
                        <img className='hidemobile' src={process.env.PUBLIC_URL + "/images/about/about-1.png"} alt="thumbnail" />
                    </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="about-team">
                    <div className="section-heading heading-left">
                        {/* <span className="subtitle">Campañas Inteligentes</span> */}
                        <h2><span style={{ fontFamily: '"Product Sans",sans-serif' }}>
                            <span className="g-blue" style={{ marginLeft: "10px" }}>G</span>
                            <span className="o-red">o</span>
                            <span className="o-yellow">o</span>
                            <span className="g-blue">g</span>
                            <span className="l-green">l</span>
                            <span className="o-red e-red"style={{ marginRight: "10px" }}>e</span>
                          </span> Ads</h2>  
                        <p>
                        Es un servicio y un programa de la empresa
Google que se utiliza para ofrecer
publicidad patrocinada a potenciales
anunciantes.
</p>
<p>
Los anuncios patrocinados de Google Ads
aparecen en la página de resultados, junto
con los resultados de búsquedas orgánicas.
Se muestran hasta 4 anuncios de la parte
superior y hasta 3 al final de la página, y se
diferencian de los resultados orgánicos porque
incluyen la leyenda "anuncio" junto a la URL
visible.
                    
                        </p>
                        <Link to="#contact" className="axil-btn btn-large btn-fill-primary">Empecemos</Link>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="section-padding-equal2"  >
            <div className="container">
                <div className="row align-items-center">
                <div className="col-lg-7">
                    <div className="about-team">
                    <div className="section-heading heading-left">
                        {/* <span className="subtitle">Campañas Inteligentes</span> */}
                       
                        <p className="light2" >
                        En <span className='zonas' style={{fontWeight: 'bold'}}>zonas de páginas web</span> aparecen en formato de imágenes, banners dinámicos, videos, etc.
</p>
<p className="light bigger">
¿Por qué es conveniente
invertir en Google Ads?
                    
                        </p>
                        
                    </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="about-team">
                    <div className="thumbnail">
                        <img src={process.env.PUBLIC_URL + "/images/about/about-2.svg"} className="hidemobile" alt="thumbnail" style={{marginTop: '-150px'}}/>
                    </div>
                    </div>
                </div>

                </div>
            </div>
        </div>
        </div>
        



    )
}

export default AboutFive;