import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BannerMktEcommerce from '../component/banner/BannerMktEcommerce';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import ProcessGrowth from '../component/process/ProcessGrowth';
import BrandOne from '../component/brand/BrandOne';
import AboutGrowth from '../component/about/AboutGrowth';
import AboutOne from '../component/about/AboutOne';
import BlogOne from '../component/blog/BlogOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import WhyChooseGrowth from '../component/whychoose/WhyChooseGrowth';
import ProjectOne from '../component/project/ProjectOne'
import WhyChoosePlatEcommerce from '../component/whychoose/WhyChoosePlatEcommerce';
import { Helmet } from 'react-helmet';


const AboutUs = () => {

    return (
        <>
        <SEO title="Ecommerce Rentable - Incrementá tus Ventas" />
        <Helmet>
        <meta name="description" content="En Merco somos expertos en Campañas de Performance para Plataforma Ecommerce. Descubre cómo mejorar el retorno de la inversión en tu Ecommerce. ¡Consultá Ahora!" />
        </Helmet>

        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BannerMktEcommerce />
                <WhyChoosePlatEcommerce />
                <BrandOne />
                <AboutOne />
                <BlogOne />
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default AboutUs;