import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll-v2';

const BannerOne = () => {
    return (
        <div className="banner banner-style-1 ">
        <div className="container ">
            <div className="row align-items-end align-items-xl-start">
                <div className="col-md-7">
                    
                    <div className="banner-content">
                        <AnimationOnScroll animateIn="fadeInUp" animateOnce={true} delay={100}>
                            <h1 className="title">Agencia de Marketing Digital especialista en Growth Performance.</h1>
  
                            <span className="subtitle">En Merco Digital somos los partners estratégicos de tu negocio. Nuestro know-how de 1 década nos permite alcanzar resultados medibles y sostenibles en el tiempo para hacer crecer tu marca. ¡Juntos podemos ser los aliados de tu empresa! </span>
                           
                           
                             <AnchorLink href='#contact' className="axil-btn btn-fill-primary btn-large"> ¡Empecemos!</AnchorLink>
                        </AnimationOnScroll>
                        
                    </div>
                </div>
                <div className="col-md-5">
                        <div className="large-thumb">
                            <img  style={{zoom: `500%!important` , maxWidth:'500%!important' }}src={process.env.PUBLIC_URL + "/images/banner/F1.webp"} alt="Laptop" />
                        </div>
                    
                </div>

            </div>
        </div>
        <ul className="list-unstyled shape-group-21">

            <li className="shape shape-4">
                <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
            </li>
            <li className="shape shape-5">
                <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
            </li>
            <li className="shape shape-6">
                <img src={process.env.PUBLIC_URL + "/images/others/bubble-40.png"} alt="Bubble" />
            </li>
            <li className="shape shape-7">
                <img src={process.env.PUBLIC_URL + "/images/others/bubble-41.png"} alt="Bubble" />
            </li>
        </ul>
    </div>
    )
}

export default BannerOne;