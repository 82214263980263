import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll-v2';


const BannerTwo = () => {
    return (
        <div className="banner banner-style-2">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="banner-content">
                        <h2 className="title"> Publicidad en <br></br>
  <strong>
    <span style={{ fontFamily: '"Product Sans",sans-serif' }} className="titleGoogle">
      <span className="g-blue" style={{ marginLeft: "10px" }}>G</span>
      <span className="o-red">o</span>
      <span className="o-yellow">o</span>
      <span className="g-blue">g</span>
      <span className="l-green">l</span>
      <span className="o-red e-red"style={{ marginRight: "10px" }}>e</span>
    </span>
  </strong>
   <span className='hidemobile'>Ads</span>
</h2>
  
                        <span className="subtitle">Somos parte del 3% de las agencias Partner Premier de Google. Rankeamos alto dentro del manejo de la herramienta publicitaria de Google Ads, alcanzando excelentes performances en las campañas publicitarias. Nuestras estrategias son 100% enfocadas en resultados. Somos Full Perfomance. </span>

                            <AnchorLink href="#contact" className="axil-btn btn-large btn-fill-white">Empecemos</AnchorLink>
                        </div>
                        
                    </div>
                </div>
            </div>
            
            <ul className="list-unstyled shape-group-18">
                <li className="shape shape-1">
                <AnimationOnScroll  animateIn="slideInRight" duration={1} animateOnce={true} delay={100}>
                </AnimationOnScroll>
                </li>
                <li className="shape shape-11">
                <AnimationOnScroll  animateIn="slideInRight" duration={1} animateOnce={true} delay={100}>
                    <img src={process.env.PUBLIC_URL + "/images/banner/banner-thumb-3.png"} alt="Shape" className='hidemobile' style={{width: "450px"}} />
                </AnimationOnScroll>
                </li>
                
                <li className="shape shape-3">
                <AnimationOnScroll  animateIn="zoomIn" duration={1} animateOnce={true} delay={500}>
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-16.png"} alt="Shape" className='hidemobile' />
                </AnimationOnScroll>
                </li>
                <li className="shape shape-4">
                <AnimationOnScroll  animateIn="zoomIn" duration={1} animateOnce={true} delay={500}>
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-15.png"} alt="Shape"className='hidemobile'  />
                </AnimationOnScroll>
                </li>
                <li className="shape shape-5">
                <AnimationOnScroll  animateIn="zoomIn" duration={1} animateOnce={true} delay={500}>
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Shape"className='hidemobile'  />
                </AnimationOnScroll>
                </li>
                <li className="shape shape-6">
                    <AnimationOnScroll  animateIn="zoomIn" duration={1} animateOnce={true} delay={500}>
                        <img src={process.env.PUBLIC_URL + "/images/others/bubble-16.png"} alt="Shape"className='hidemobile'  />
                    </AnimationOnScroll>
                </li>
                <li className="shape shape-7">
                    <AnimationOnScroll  animateIn="slideInDown" duration={1} animateOnce={true} delay={500}>
                        <img src={process.env.PUBLIC_URL + "/images/others/bubble-26.png"} alt="Shape"className='hidemobile'  />
                    </AnimationOnScroll>
                </li>
            </ul>
        </div>
    )
}

export default BannerTwo;