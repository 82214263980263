import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import SectionTitle from '../../elements/section-title/SectionTitle';
import { FaCompress, FaCode, FaGlobe, FaBrush, FaArrowAltCircleUp, FaRProject, FaAddressBook } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll-v2'

const WhyChooseOne = () => {
    return (
      <div className="section-padding bg-color-light">
        <div className="container">
            <div className="row">
            <div className="col-lg-6">
                <div className="why-choose-us">
                <SectionTitle 
                    subtitle="Desarrollo Web en Wordpress"
                    title="Como empezamos..."
                    description="Programamos una auditoría para conocer el funcionamiento de la institución, sus objetivos, los servicios que ofrece, el público al que está dirigido y los valores que proclama. Diseñamos una página web que cumpla con todos los requisitos y especificidades de tu organización, y la adaptamos a su estética previa."
                    textAlignment="heading-left"
                    textColor=""
                />
                    <Accordion defaultActiveKey="1">
                        <Accordion.Item eventKey="1">
                            <Accordion.Header><FaCompress />Usabilidad
                            </Accordion.Header>
                            <Accordion.Body>
                            Nos encargamos de que tu sitio web sea sencillo de utilizar, funcional, y que pueda visualizarse desde todos los dispositivos, como celulares o tablets.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header><FaCode />Ecommerce
 </Accordion.Header>
                            <Accordion.Body>
                          
                            Podés ofrecer una tienda online para acceder a cursos online, pagar cuotas, comprar merchandising o hacer colaboraciones.


                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header><FaGlobe /> Autoadministrable
</Accordion.Header>
                            <Accordion.Body>
                            Publicá, eliminá y modificá tu página web todas las veces que quieras. Mantenerla actualizada es muy sencillo. El dominio te pertenece.
                            </Accordion.Body>
                        </Accordion.Item>
                       
                    </Accordion>
                    
                </div>
            </div>
            <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="why-choose-us mb--30">
                <div className="why-choose-thumb">
                    <img src={process.env.PUBLIC_URL + "/images/about/about-3-website-institu.png"} alt="Office" />

                </div>
                </div>
            </div>
            </div>


            <div className="row" style={{marginTop:'100px'}} >
            <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="why-choose-us mb--30">
                <div className="why-choose-thumb">
                    <img src={process.env.PUBLIC_URL + "/images/about/about-4-website-institu.png"} alt="Office" />
                </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="why-choose-us">
                    
                <SectionTitle 
                    subtitle=""
                    title="
                    Mostrá todo lo que 
                    tu institución tiene
                    para ofrecer
                    
                    "
                    description="
                    "
                    textAlignment="heading-left"
                    textColor=""
                    textSize="10pt"
                />
  <Accordion defaultActiveKey="1">
                        <Accordion.Item eventKey="1">
                            <Accordion.Header><FaCompress />Galería de imágenes.


                            </Accordion.Header>
                            <Accordion.Body>
                            Cargá fotos y videos con regularidad. Compartí con los usuarios
imágenes de eventos, fechas especiales, del personal o de las
instalaciones. Podés crear álbumes de fotos y actualizarlos
cuantas veces quieras.



                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header><FaCode />Calendario de eventos
 </Accordion.Header>
                            <Accordion.Body>
                            Programá un calendario para que todos los usuarios tengan conocimiento sobre los próximos eventos que estás planificando y no se pierdan ninguno.

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header><FaGlobe /> Blog</Accordion.Header>
                            <Accordion.Body>
                            Publicá notas e información detallada sobre los valores de tu organización, las tareas que realizan, curiosidades del rubro, publicaciones en los medios y todo lo que quieras comunicar.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header><FaAddressBook /> Colaboraciones </Accordion.Header>
                            <Accordion.Body>
                            Podés incluir diferentes métodos de pago para quienes quieran donar, comprar cursos y merchandising, pagar cuotas y muchas cosas más. Facilitale la vida a tus alumnos o socios.
                            </Accordion.Body>
                        </Accordion.Item>


                       
                    </Accordion>

                        <AnchorLink href="#contact" className="axil-btn btn-large btn-fill-white" style={{marginTop: '50px'}}>Empecemos</AnchorLink>

                </div>
            </div>
            
            </div>
            
        </div>
        </div>

    )
}

export default WhyChooseOne;