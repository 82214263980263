import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import SectionTitle from '../../elements/section-title/SectionTitle';
import { FaCompress, FaCode, FaGlobe, FaBrush } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll-v2'

const WhyChooseOne = () => {
    return (
      <div className="section-padding bg-color-light">
        <div className="container">
            <div className="row">
            <div className="col-lg-6">
                <div className="why-choose-us">
                <SectionTitle 
                    subtitle="Publicidad en Facebook"
                    title="Estas listo para empezar en Facebook?"
                    description=""
                    textAlignment="heading-left"
                    textColor=""
                />
                    <Accordion defaultActiveKey="1">
                        <Accordion.Item eventKey="1">
                            <Accordion.Header><FaCompress /> Optimizaciones personalizadas</Accordion.Header>
                            <Accordion.Body>
                            La publicidad en Facebook se gestiona según un modelo de precios CPA, CPM, o CPC. Nuestras optimizaciones tienen como objetivo garantizar que el coste por venta o adquisición sea siempre el más bajo posible con tus anuncios.

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header><FaCode /> Pagás sólo cuando hay resultados</Accordion.Header>
                            <Accordion.Body>
                            El pago se realiza sólo cuando la audiencia o clientes potenciales interactúan o realizan la acción deseada: comprar, suscribirse, consultar o reproducir los vídeos, entre otros muchos objetivos que puedes establecer con una audiencia.

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header><FaGlobe /> Geo-segmentación</Accordion.Header>
                            <Accordion.Body>
                            Tienes la posibilidad de segmentar geográficamente tu audiencia hasta 1 km. alrededor del punto que indiques en el mapa. Esto significa que podemos crear una campaña o anuncios para llegar a aquellas personas que realmente pueden llegar a convertirse en tus clientes.

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header><FaBrush /> Personalización total</Accordion.Header>
                            <Accordion.Body>
                            Al anunciarte en Facebook Ads, puedes elegir dónde quieres lanzar tu campaña, por ejemplo: MarketPlace, feed, historias, sitios asociados, entre otras múltiples opciones. Adaptamos el contenido a cada formato para crear anuncios.

                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    
                </div>
            </div>
            <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="why-choose-us mb--30">
                <div className="why-choose-thumb">
                    <img src={process.env.PUBLIC_URL + "/images/about/about-2-facebook-nueva.png"} alt="Office" />
                </div>
                </div>
            </div>
            </div>


            <div className="row">
            <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="why-choose-us mb--30">
                <div className="why-choose-thumb">
                    <img src={process.env.PUBLIC_URL + "/images/about/about-4-facebook.png"} alt="Office" />
                </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="why-choose-us">
                    
                <SectionTitle 
                    subtitle=""
                    title="Campañas Optimizadas"
                    description=""
                    textAlignment="heading-left"
                    textColor=""
                />
                  <p>
                  Contamos con un servicio de optimización y mantenimiento continuo de Campañas de Facebook Ads (anuncios de Facebook) que te permitirá mejorar el rendimiento y enfocarte en el cumplimiento de los objetivos planteados en tiempo CERO.

                  </p>
                  <p>
                  En ese sentido, nuestro objetivo es que tu Campaña de Facebook Ads esté siempre actualizada (ya que el mercado siempre está en constante fluctuación) para que puedas optimizar el uso de tus recursos, generando así mejores resultados y reconocimiento de marca.
                  </p>

                  <AnchorLink href="#contact" className="axil-btn btn-large btn-fill-white">Empecemos</AnchorLink>
                </div>
            </div>
            
            </div>
            
        </div>
        </div>

    )
}

export default WhyChooseOne;