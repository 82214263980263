import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BannerSitioInstitucional from '../component/banner/BannerSitioInstitucional';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import ProcessGrowth from '../component/process/ProcessGrowth';
import BrandOne from '../component/brand/BrandOne';
import AboutGrowth from '../component/about/AboutGrowth';
import AboutOne from '../component/about/AboutOne';
import BlogOne from '../component/blog/BlogOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import WhyChooseGrowth from '../component/whychoose/WhyChooseGrowth';
import ProjectOne from '../component/project/ProjectOne'
import WhyChooseSitioInstitucional from '../component/whychoose/WhyChooseSitioInstitucional';

const SitioInstitucional = () => {

    return (
        <>
        <SEO title="Sitio Institucional " />
        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BannerSitioInstitucional />
                <ProjectOne />
                <WhyChooseSitioInstitucional />
                <BrandOne />
                <AboutOne />
                <BlogOne />
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default SitioInstitucional;