import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import SectionTitle from '../../elements/section-title/SectionTitle';
import { FaCompress, FaCode, FaGlobe, FaBrush } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll-v2'

const WhyChooseOne = () => {
    return (
      <div className="section-padding bg-color-light">
        <div className="container">
            <div className="row">
            <div className="col-lg-6">
                <div className="why-choose-us">
                <SectionTitle 
                    subtitle="Growth Performance"
                    title="Estas listo para comenzar con Growth  Performance?"
                    description=""
                    textAlignment="heading-left"
                    textColor=""
                />
                                        <p>
                            El performance es una herramienta de marketing digital en la que el anunciante sólo paga por los resultados obtenidos: suscripciones, likes, ventas, entre otros. En Merco te proponemos trabajar con Google Ads y Social Ads para que le saques el máximo partido a tus inversiones.
                        </p>
                        <Link to="#contact" className="axil-btn btn-large btn-fill-white">Empecemos</Link>
                    
                </div>
            </div>
            <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="why-choose-us mb--30">
                <div className="why-choose-thumb">
                <img src={process.env.PUBLIC_URL + "/images/about/GrowthPerformance.png"} alt="thumbnail" />
                </div>
                </div>
            </div>
            </div>


            <div className="row">
            <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="why-choose-us mb--30">
                <div className="why-choose-thumb">
                    <img src={process.env.PUBLIC_URL + "/images/about/GrowthPerformance2.png"} className="hidemobile" alt="Office" />
                </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="why-choose-us">
                <SectionTitle 
                    subtitle=""
                    title="Que es Growth Performance?"
                    description=""
                    textAlignment="heading-left"
                    textColor=""
                />

                 <Accordion defaultActiveKey="1"style={{marginTop: "1px"}}> 
                        <Accordion.Item eventKey="1">
                            <Accordion.Header><FaCompress /> Experiencia </Accordion.Header>
                            <Accordion.Body>
                            Somos expertos en generar tráfico de calidad y por consiguiente consultas que se convierten en oportunidades de negocio.

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header><FaCode /> Ecommerce </Accordion.Header>
                            <Accordion.Body>
                            Realizamos campañas para conseguir compras en ecommerce e intenciones onsite. Intervenimos en el proceso digital desde campañas de tráfico a fidelización de clientes.


                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header><FaGlobe /> Campañas inteligentes </Accordion.Header>
                            <Accordion.Body>
                            Configuramos audiencias avanzadas, campañas de Google shopping e Instagram shopping, Whatsapp Business, Discovery y Remarketing.

                            </Accordion.Body>
                        </Accordion.Item>
                        
                    </Accordion>

            
                </div>
                <AnchorLink href="#contact" className="axil-btn btn-large btn-fill-white" style={{ marginTop: "2em" }}>Empecemos</AnchorLink>
            </div>
            
            </div>
            
        </div>
        </div>

    )
}

export default WhyChooseOne;