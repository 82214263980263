import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import SectionTitle from '../../elements/section-title/SectionTitle';
import { FaCompress, FaCode, FaGlobe, FaBrush, FaArrowAltCircleUp, FaRProject, FaAddressBook } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll-v2'

const WhyChooseOne = () => {
    return (
      <div className="section-padding bg-color-light">
        <div className="container">
            <div className="row">
            <div className="col-lg-6">
                <div className="why-choose-us">
                <SectionTitle 
                    subtitle="Desarrollo Web en Wordpress"
                    title="Como empezamos..."
                    description="Conoceremos tus objetivos y expectativas para tu página web. Definiremos el presupuesto, el diseño y las características que querés que tu página tenga. Buscaremos una plantilla de WordPress que se adapte a tu medida y le haremos los ajustes necesarios hasta que esté lista para usar y administrar.Analizamos el proyecto, sus funcionalidades y público objetivo. Estudiamos las últimas tendencias en el rubro y a tu competencia."
                    textAlignment="heading-left"
                    textColor=""
                />
                    <Accordion defaultActiveKey="1">
                        <Accordion.Item eventKey="1">
                            <Accordion.Header><FaCompress />Diseño
                            </Accordion.Header>
                            <Accordion.Body>
                            Integramos y optimizamos la estética de tu marca (en caso de que ya la tengas delimitada) con el look & feel del sitio web.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header><FaCode />Experiencia
de usuario
 </Accordion.Header>
                            <Accordion.Body>
                          
                            Comprobamos que todo en el sitio funcione bien para que la experiencia del usuario sea sencilla y satisfactoria.

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header><FaGlobe /> Autoadministrable</Accordion.Header>
                            <Accordion.Body>
                            Una vez lista, podrás administrarla a tu gusto. Modificá precios, productos, banners, textos, promociones o lo que quieras. ¡El dominio es tuyo!
                            </Accordion.Body>
                        </Accordion.Item>
                       
                    </Accordion>
                    
                </div>
            </div>
            <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="why-choose-us mb--30">
                <div className="why-choose-thumb">
                <img src={process.env.PUBLIC_URL + "/images/about/about-3-wordpress.png"} alt="Office" />


                </div>
                </div>
            </div>
            </div>


            <div className="row" style={{marginTop:'100px'}} >
            <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="why-choose-us mb--30">
                <div className="why-choose-thumb">
                    <img src={process.env.PUBLIC_URL + "/images/about/about-4-wordpress.png"} alt="Office" />
                </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="why-choose-us">
                    
                <SectionTitle 
                    subtitle=""
                    title="¿Estás listo para
                    crear tu página web?
                    "
                    description="
                    "
                    textAlignment="heading-left"
                    textColor=""
                    textSize="10pt"
                />
  <Accordion defaultActiveKey="1">
                        <Accordion.Item eventKey="1">
                            <Accordion.Header><FaCompress />Tené tu sitio en 15 días.

                            </Accordion.Header>
                            <Accordion.Body>
                            El hecho de elegir plantillas predeterminadas hace que el proceso de
diseño sea mucho más rápido. Solo las adaptamos a la estética de
la marca y listo: ¡Ya la podés usar!


                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header><FaCode /> Dominio propio. </Accordion.Header>
                            <Accordion.Body>
                            Obtené tu propio dominio pagando solo un pequeño arancel anual. Elegí un nombre de dominio simple, lo más similar al nombre de tu negocio para que a los usuarios les resulte fácil encontrarte.

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header><FaGlobe /> Variabilidad de opciones.</Accordion.Header>
                            <Accordion.Body>
                            Podés crear una página web para lo que quieras: tiendas de comercio electrónico, sitios web de negocios, institucionales, educativos, portfolios y blogs. Hay miles de formatos y diseños distintos.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header><FaAddressBook /> Herramientas para SEO</Accordion.Header>
                            <Accordion.Body>
                            WordPress ofrece varios plugins y funciones para ayudarte a mejorar el SEO de tu sitio web, como palabras clave, modificación del código, métricas, ubicación, optimización del sitio, etc.

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header><FaAddressBook /> Seguridad </Accordion.Header>
                            <Accordion.Body>
                            WordPress ofrece un sitio web seguro al que se le puede agregar plugins como filtrado de spam en comentarios, verificación de vulnerabilidades, escaneo de malware, monitoreo de listas negras y firewall.
                            </Accordion.Body>
                        </Accordion.Item>
                       
                    </Accordion>

                        <AnchorLink href="#contact" className="axil-btn btn-large btn-fill-white" style={{marginTop: '50px'}}>Empecemos</AnchorLink>

                </div>
            </div>
            
            </div>
            
        </div>
        </div>

    )
}

export default WhyChooseOne;