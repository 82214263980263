import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BannerSEO from '../component/banner/BannerSEO';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import ProcessGrowth from '../component/process/ProcessGrowth';
import ServiceProp from '../component/service/ServiceProp';
import BrandOne from '../component/brand/BrandOne';
import AboutGrowth from '../component/about/AboutGrowth';
import AboutOne from '../component/about/AboutOne';
import BlogOne from '../component/blog/BlogOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import WhyChooseGrowth from '../component/whychoose/WhyChooseGrowth';
import ProjectOne from '../component/project/ProjectOne'
import ServicePropOne from '../component/service/ServicePropOne'
import WhyChooseSEO from '../component/whychoose/WhyChooseSEO';
import { Helmet } from 'react-helmet';


const AboutUs = () => {

    return (
        <>
        <SEO title="Posicionamiento SEO en Google" />
        <Helmet>
        <meta name="description" content=" En Merco Digital contamos con más de 10 años de experiencia en posicionamiento web en Google. ¡Alcanza los primeros resultados de búsqueda orgánica! " />
        </Helmet>

        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BannerSEO />
                <ProjectOne />
                <div className="section section-padding">
            <div className="container">
            <SectionTitle 
                subtitle="Publicidad en Google"
                title="¿Cómo Trabajamos?"
                description=""
                textAlignment="heading-left mb--20 mb_md--70"
                textColor=""
            />
                <div className="row">
                    <ServicePropOne colSize="col-lg-4" serviceStyle="service-style-2" itemShow="3" marginTop="yes"/>
                </div>
            </div>
            <ul className="shape-group-7 list-unstyled">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-2.png"} alt="circle" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="Line" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Line" /></li>
            </ul>

        </div>
        <WhyChooseSEO />
                <BrandOne />
                <AboutOne />
                <BlogOne />
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default AboutUs;