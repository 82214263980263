import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import AboutOne from '../component/about/AboutOne';
import AboutThree from '../component/about/AboutThree';
import BannerOne from '../component/banner/BannerOne';
import BlogOne from '../component/blog/BlogOne';
import BrandOne from '../component/brand/BrandOne';
import CounterUpOne from '../component/counterup/CounterUpOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import VideoTwo from '../component/video/VideoTwo';
import PricingOne from '../component/pricing/PricingOne';
import ProjectOne from '../component/project/ProjectOne';
import TeamOne from '../component/team/TeamOne';
import ServicePropOne from '../component/service/ServicePropOne';
import TestimonialOne from '../component/testimonial/TestimonialOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import AnchorLink from 'react-anchor-link-smooth-scroll-v2';
import FaqOne from '../component/faq/FaqOne';
import {Helmet} from 'react-helmet';

import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Link } from 'react-router-dom'

const DigitalAgency = () => {

    return (
        <>
        <SEO title="Agencia de Marketing Digital – Growth Performance | Merco Digital"/>
               <Helmet>

               <meta name="description" content="Agencia de Marketing Digital para Pymes y Grandes Empresas. Trabajamos junto a nuestros clientes para generar resultados 100% medibles. ¡Consúltanos ahora!" />
        </Helmet>
       
        <ColorSwitcher />
        <main className="main-wrapper">
            <HeaderOne />
             <BannerOne /> 
             <section id='stuff' />
         


            
            <AboutThree />
            <section id='video' />
            <VideoTwo />
{/*             <TeamOne /> */}
<BrandOne />
            <AboutOne />
            <FaqOne />
            
            <ProjectOne />
            <div>






  </div>


{/* 

            <div className="section section-padding-2 bg-color-light">
                <div className="container">
                    <SectionTitle 
                        subtitle="Business Growth"
                        title="Que es full performance?"
                        description="Somos una agencia de Marketing digital compuesta por 15 profesionales con ADN Colaborativo."
                        textAlignment="heading-left"
                        textColor="dark"
                    />
                    <div className='row'>
                        <ServicePropOne colSize="col-xl-4 col-md-6" serviceStyle="" itemShow="20" />
                    </div>
                </div>
                <ul className="list-unstyled shape-group-10">

                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-42.png"} alt="Circle" /></li>
                    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-43.png"} alt="Circle" /></li>
                </ul>
            </div> */}
            {/* <BlogOne /> */}
            <CtaLayoutOne /> 
        <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default DigitalAgency;

