import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BannerCommunityManager from '../component/banner/BannerCommunityManager';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import ProcessGrowth from '../component/process/ProcessGrowth';
import BrandOne from '../component/brand/BrandOne';
import AboutGrowth from '../component/about/AboutGrowth';
import AboutOne from '../component/about/AboutOne';
import BlogOne from '../component/blog/BlogOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import WhyChooseGrowth from '../component/whychoose/WhyChooseGrowth';
import ProjectOne from '../component/project/ProjectOne'
import WhyChooseCommunityManager from '../component/whychoose/WhyChooseCommunityManager';
import FaqCommunityManager from  '../component/faq/FaqCommunityManager';
import {Helmet} from 'react-helmet';

const CommunityManager = () => {

    return (
        <>
        <SEO title="Community Manager & Gestión de redes" />
        <Helmet>

        <meta name="description" content="Ofrecemos un Servicio de Community Manager enfocado en la generación de un contenido atractivo y de valor que sume seguidores. ¡Conectá con tu audiencia!" />

        </Helmet>

        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BannerCommunityManager />
                <WhyChooseCommunityManager />
                <BrandOne />
                <AboutOne />
                <FaqCommunityManager />
{/*                 <BlogOne />
 */}                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>

        </>
    )
}

export default CommunityManager;