import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BannerNosotros from '../component/banner/BannerNosotros';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import ProcessOne from '../component/process/ProcessOne';
import ProcessNosotros from '../component/process/ProcessNosotros';
import ServicePropOne from '../component/service/ServicePropOne';
import BrandOne from '../component/brand/BrandOne';
import AboutFive from '../component/about/AboutFive';
import AboutSix from '../component/about/AboutSix';
import AboutOne from '../component/about/AboutOne';
import BlogOne from '../component/blog/BlogOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import FaqPublicidadGoogle from '../component/faq/FaqPublicidadGoogle'
import ProjectNosotros from '../component/project/ProjectNosotros';
import {Helmet} from 'react-helmet';
const AboutUs = () => {

    return (
        
        <>
        <SEO title="Agencia Partner Premier de Google " />
        <Helmet>
        <meta name="description" content="Como Agencia Partner Premier de Google, tenemos especializaciones en Búsqueda, Display y Google Shopping. Conseguimos resultados al menor costo, para tus campañas publicitarias. ¡Conócenos!" />
        </Helmet>

        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BannerNosotros />
                <ProjectNosotros />
                <ProcessNosotros />
                <BrandOne />
                <AboutOne />
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default AboutUs;