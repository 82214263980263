import React from 'react';
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll-v2'
import TeamData from "../../data/team/TeamData.json";
import { slugify } from '../../utils';
import SectionTitle from '../../elements/section-title/SectionTitle';
const allData = TeamData;

const TeamOne = () => {
    return (
        <div className="section section-padding bg-color-blue pb--70 pb_lg--20 pb_md--0">
        <div className="container col-md-10">
        <h1 className="teamtitle">Partners con ADN versátil y colaborativo</h1>
        <h4 className="teamsubtitle">
        Vamos a fondo para lograr resultados reales y medibles. Nuestro afán por alcanzar los objetivos planteados con cada cliente nos fomenta priorizar la toma de decisiones en acciones estratégicas concretas y eficientes.
                                </h4>
            <div className="row">
                {allData.map((data) => (
                    <div className="col-xl-3 col-sm-6" key={data.id}>
                        <div className="team-grid">
                            <div className="thumbnail">
                                <Link to={process.env.PUBLIC_URL + `/team-details/${slugify(data.title)}`}>
                                    <img src={process.env.PUBLIC_URL + data.thumb} alt={data.title} />
                                </Link>
                            </div>
                            <div className="content">
                                <h4 className="title">
                                    <Link to={process.env.PUBLIC_URL + `/team-details/${slugify(data.title)}`}>
                                        {data.title}
                                    </Link>
                                </h4>
                                <span className="teamsubtitlemini" dangerouslySetInnerHTML={{__html: data.designation}}></span>
                                
                            </div>
                        </div>
                    </div>
                ))}
                 <Link to={process.env.PUBLIC_URL + "/publicidad-en-google-ads"} className="axil-btn btn-fill-white btn-small"  style={{width: '50%', marginLeft: '25%'}}>¡Quiero conocerlos!</Link>

            </div>
            
        </div>
        
        <ul className="list-unstyled shape-group-10">
{/*             <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>
            <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="Circle" /></li>
            <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Circle" /></li> */}
        </ul>
    </div>

    )
}

export default TeamOne;