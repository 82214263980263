import React from 'react';
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll-v2'
import { FaFacebookF, FaTwitter, FaPinterestP, FaLinkedin, FaInstagram, FaVimeoV, FaDribbble, FaEnvelopeOpen, FaYoutube } from "react-icons/fa";
import ServiceData from "../../data/service/ServiceMain.json";
import { slugify } from '../../utils';
import Logo from '../../elements/logo/Logo';

const getServiceData = ServiceData;

const FooterOne = ({parentClass}) => {
    
    return (
        <footer className={`footer-area ${parentClass}`}>
            <div className="container">
                <div className="footer-top">
                    <div className="footer-social-link">
                        
                    </div>
                </div>
                <div className="footer-main">
                    <div className="row">
                        <div className="col-xl-6 col-lg-5">
                            <div className="footer-widget border-end">
                                <div className="footer-newsletter">
                                <div >
                                <img src={process.env.PUBLIC_URL + "/images/logo-footer.svg"} alt="logo" width={400}/>

                            </div>
{/*                                     <form>
                                        <div className="input-group">
                                            <span className="mail-icon"><FaEnvelopeOpen /> </span>
                                            <input type="email" className="form-control" placeholder="Email address" />
                                            <button className="subscribe-btn" type="submit">Subscribe</button>
                                        </div>
                                    </form> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-7">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Menu</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                              
                                                    <li ><Link to={process.env.PUBLIC_URL + `/publicidad-en-google-ads`}>Publicidad en Google</Link></li>
                                                    <li ><Link to={process.env.PUBLIC_URL + `/posicionamiento-seo`}>Posicionamiento SEO</Link></li>
                                                    <li ><Link to={process.env.PUBLIC_URL + `/plataformas-ecommerce`}>Plataformas para E-Commerce</Link></li>
                                                    <li ><Link to={process.env.PUBLIC_URL + `/sitio-institucional`}>Sitios Institucionales</Link></li>


                                            
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Redes</h6>
                                        <div className="footer-menu-link">
                                        <ul className="list-unstyled">
                            <li><FaFacebookF /> |<a href="https://www.facebook.com/MercoDigital/" target="_blank" rel="noopener noreferrer"> @mercodigital</a></li>
                            <li><FaLinkedin /> |<a href="https://www.linkedin.com/company/2185266/"target="_blank" rel="noopener noreferrer"> @mercodigital </a></li>
                            <li><FaInstagram /> |<a href="https://www.instagram.com/mercogrowthmkt/"target="_blank" rel="noopener noreferrer"> @mercogrowthmkt </a></li>
                            <li><FaYoutube /> <a href="https://www.youtube.com/channel/UCkgbp5TUDJNWbEkwwn2ZicA" target="_blank" rel="noopener noreferrer">|@mercodigitalbuenosaires </a></li>
                        </ul>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom"> 
                    <div className="row">
                        <div className="col-md-12 offset-md-4">
                            <div className="footer-copyright">
                                <span className="copyright-text">© {new Date().getFullYear()}. copyright 2022 Merco. Digital Pole Position.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterOne;