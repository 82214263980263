import React, { useState } from 'react';
import { FaPlay } from 'react-icons/fa';
import FsLightbox from 'fslightbox-react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import background from "../../assets/img/video-bg.png";
import AnchorLink from 'react-anchor-link-smooth-scroll-v2'

const VideoTwo = () => {
    
    const [toggler, setToggler] = useState(false);

    return (
        <>
            <div className="section section-padding-equal-video hidemobile"  style={{ backgroundImage: `url(${background})` }}>
                <div className="container">
{/*                     <SectionTitle 
                        subtitle="Experts in field"
                        title="Design startup <br> movement"
                        description=""
                        textAlignment=""
                        textColor=""
                    /> */}
                    <div className="about-expert">
                        <div className="thumbnail">
                            <img src={process.env.PUBLIC_URL + "/images/about/about-1.webp"} alt="Thumbnail" />
                            <div className="popup-video">
                                <button className="play-btn" onClick={ () => setToggler(!toggler) }><FaPlay /></button>
                            </div>
                        </div>
{/*                         <ul className="frame-shape list-unstyled">
                            <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/about/frame-1.png"} alt="Frame" /></li>
                            <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/about/frame-2.png"} alt="Frame" /></li>
                        </ul> */}
                                            

                    </div>
                </div>

                <ul className="shape-group-16 list-unstyled">
{/*                     <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-2.png"} alt="circle" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="Line" /></li>
                    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Line" /></li> */}
                </ul>

            </div>
            
           <img src={process.env.PUBLIC_URL + "/images/about/growth.png"} className="Thumbnail hidemobile"  /> 
 <img src={process.env.PUBLIC_URL + "/images/about/mobile-growth.svg"} className="Thumbnail hidedesktop" width={600} />
  
 <div className='growthButton'>

<AnchorLink  href='#contact' className="hidemobile axil-btn btn-large btn-fill-primary">
     Quiero Iniciar
</AnchorLink>
</div>

            <FsLightbox toggler={ toggler } sources={ ['https://www.youtube.com/watch?v=wLjXvo90zJA'] }/>
        </>
    )
}

export default VideoTwo;

