import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll-v2';


const BannerTwo = () => {
    return (
        <div className="banner banner-style-generic">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="banner-content">
                        <h2 className="title"> Plataformas e-Commerce
</h2>
  
                        <span className="subtitle">Necesitas que tu tienda esté en Internet, por eso diseñamos un sitio web de e-commerce personalizado que se ajuste a las necesidades de tu negocio. Incrementa tus ventas, agiliza los procesos de compra y expandete a nuevos mercados. ¡Convierte a los visitantes en clientes!
</span>

                            <AnchorLink href="#contact" className="axil-btn btn-large btn-fill-white">Empecemos</AnchorLink>
                        </div>
                        
                    </div>
                </div>
            </div>
            
            <ul className="list-unstyled shape-group-18">

                <li className="shape shape-11">
                <AnimationOnScroll  animateIn="slideInRight" duration={1} animateOnce={true} delay={100}>
                    <img src={process.env.PUBLIC_URL + "/images/banner/banner-thumb-1.png"} alt="Shape" className='hidemobile' style={{zoom: "80%"}} />
                </AnimationOnScroll>
                </li>

                <li className="shape shape-3">
                <AnimationOnScroll  animateIn="zoomIn" duration={1} animateOnce={true} delay={500}>
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-16.png"} alt="Shape" className='hidemobile' />
                </AnimationOnScroll>
                </li>
                <li className="shape shape-4">
                <AnimationOnScroll  animateIn="zoomIn" duration={1} animateOnce={true} delay={500}>
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-15.png"} alt="Shape"className='hidemobile'  />
                </AnimationOnScroll>
                </li>
                <li className="shape shape-5">
                <AnimationOnScroll  animateIn="zoomIn" duration={1} animateOnce={true} delay={500}>
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Shape"className='hidemobile'  />
                </AnimationOnScroll>
                </li>
                <li className="shape shape-6">
                    <AnimationOnScroll  animateIn="zoomIn" duration={1} animateOnce={true} delay={500}>
                        <img src={process.env.PUBLIC_URL + "/images/others/bubble-16.png"} alt="Shape"className='hidemobile'  />
                    </AnimationOnScroll>
                </li>
                <li className="shape shape-7">
                    <AnimationOnScroll  animateIn="slideInDown" duration={1} animateOnce={true} delay={500}>
                        <img src={process.env.PUBLIC_URL + "/images/others/bubble-26.png"} alt="Shape"className='hidemobile'  />
                    </AnimationOnScroll>
                </li>
            </ul>
        </div>
    )
}

export default BannerTwo;