import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import SectionTitle from '../../elements/section-title/SectionTitle';
import { FaCompress, FaCode, FaGlobe, FaBrush, FaArrowAltCircleUp, FaRProject, FaAddressBook } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll-v2'

const WhyChooseOne = () => {
    return (
      <div className="section-padding bg-color-light">
        <div className="container">
            <div className="row">
            <div className="col-lg-6">
                <div className="why-choose-us">
                <SectionTitle 
                    subtitle="Posicionamiento en Google"
                    title="¿Qué incluye el <strong> <em>servicio de
                    Community Manager? </em>  </strong> "
                    description="Nos adaptamos al estilo de cada cliente en particular. Conocemos los lenguajes específicos que se manejan en toda red social, y sabemos exactamente cómo escribir para persuadir y llamar la atención de tu público objetivo."
                    textAlignment="heading-left"
                    textColor=""
                />
                    <Accordion defaultActiveKey="1">
                        <Accordion.Item eventKey="1">
                            <Accordion.Header><FaCompress /> Diseño de contenidos

                            </Accordion.Header>
                            <Accordion.Body>
                            Nuestros diseñadores gráficos están capacitados para crear piezas únicas, adaptadas a las nuevas tendencias y a las preferencias de los usuarios. Diseñamos infografías, fotomontajes, gifs y videos con una impronta única.

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header><FaCode /> Gestión de redes
sociales
 </Accordion.Header>
                            <Accordion.Body>
                          
 

                            No tienes que preocuparte por subir contenido, nuestro equipo lo hace por vos. Trabajamos con un sistema de calendarización que te permitirá conocer de antemano qué contenidos se van a publicar, qué días y en qué horario.


                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header><FaGlobe /> Moderación</Accordion.Header>
                            <Accordion.Body>
                 
Cuidamos la reputación de tu negocio. Protegemos y potenciamos la relación con tu público y creamos un sentido de comunidad. Solucionamos problemas, atendemos consultas, asesoramos y generamos feedback en los comentarios.

                            </Accordion.Body>
                        </Accordion.Item>
                       
                    </Accordion>
                    
                </div>
            </div>
            <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="why-choose-us mb--30">
                <div className="why-choose-thumb">
                    <img src={process.env.PUBLIC_URL + "/images/about/about-3-reputacion-online.png"} alt="Office" />
                </div>
                </div>
            </div>
            </div>


            <div className="row">
            <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="why-choose-us mb--30">
                <div className="why-choose-thumb">
                    <img src={process.env.PUBLIC_URL + "/images/about/about-4-reputacion-online.png"} alt="Office" />
                </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="why-choose-us">
                    
                <SectionTitle 
                    subtitle=""
                    title="
                    Posicioná tu
                    marca en las
                    redes sociales
                    "
                    description=""
                    textAlignment="heading-left"
                    textColor=""
                    textSize="10pt"
                />
  <Accordion defaultActiveKey="1">
                        <Accordion.Item eventKey="1">
                            <Accordion.Header><FaCompress /> 
                            Branding

                            </Accordion.Header>
                            <Accordion.Body>
                            Trabajamos para que tu marca se haga conocida, deseada
y tenga un impacto positivo en la mente de los usuarios.
Promovemos tus valores y aquello que te hace único para
crear una conexión con tu público y fidelizarlo.



                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header><FaCode />Contenido de valor
. </Accordion.Header>
                            <Accordion.Body>
                          
                            El contenido de valor se caracteriza por aportar al usuario información, inspiración o entretenimiento que tenga coherencia con la marca, para no limitarnos a publicar contenido repetitivo sobre productos o servicios.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header><FaGlobe /> Crecimiento orgánico

</Accordion.Header>
                            <Accordion.Body>
                         
El crecimiento orgánico significa que los usuarios llegarán a ti por voluntad propia o por recomendaciones, pero no por publicidad. Conectá con los usuarios y sumá seguidores a través de contenido atractivo y de valor.

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header><FaAddressBook />Fidelización
</Accordion.Header>
                            <Accordion.Body>
                        
Los principios de la atención al público se trasladan al mundo virtual. Es muy importante hablarle a tus seguidores con respeto y amabilidad para garantizarles una buena experiencia y convertirlos en fieles clientes.

                            </Accordion.Body>
                        </Accordion.Item>


                        <Accordion.Item eventKey="5">
                            <Accordion.Header><FaAddressBook /> Análisis de la competencias


</Accordion.Header>
                            <Accordion.Body>
                            Observamos el comportamiento y las estrategias de tus competidores en las redes sociales. Estudiamos sus fortalezas e identificamos sus debilidades. Desarrollamos un plan de acción para trabajar tu valor diferencial.
                            </Accordion.Body>
                        </Accordion.Item>
                       
                    </Accordion>

                        <AnchorLink href="#contact" className="axil-btn btn-large btn-fill-white" style={{marginTop: '50px'}}>Empecemos</AnchorLink>

                </div>
            </div>
            
            </div>
            
        </div>
        </div>

    )
}

export default WhyChooseOne;