import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BannerFB from '../component/banner/BannerFB';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import ProcessOne from '../component/process/ProcessOne';
import ServicePropOne from '../component/service/ServicePropOne';
import BrandOne from '../component/brand/BrandOne';
import WhyChooseOne from '../component/whychoose/WhyChooseOne';
import AboutOne from '../component/about/AboutOne';
import BlogOne from '../component/blog/BlogOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import ContactLocation from '../component/contact/ContactLocation';
import { Helmet } from 'react-helmet';
const PersonalPortfolio = () => {



    return (
        <>
        <SEO title="Publicidad en Facebook - Estrategias en Social Media" />
        <meta name="description" content="Con la Publicidad en Facebook ofrecemos un servicio de mantenimiento y optimización continuo de tus campañas, para enfocarnos en alcanzar tus objetivos. " />
        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BannerFB 
                title="Publicidad  <br> en Google Ads."
                paragraph ="La herramienta publicitaria de Google Ads permite
                posicionar en el buscador los anuncios patrocinados de
                forma inmediata para que Pymes y Grandes Empresas
                compitan en igualdad de condiciones en un mismo canal,
                derribando cualquier barrera geográfica y económica.
                Creamos estrategias 100% enfocadas en Resultados,
                Somos Full Perfomance."
                styleClass="thumbnail-4"
                mainThumb="/images/banner/banner-thumb-3.png"
                />
        <div className="section section-padding">
            <div className="container">
            <SectionTitle 
                subtitle="Publicidad en Facebook"
                title="¿Cómo Trabajamos?"
                description=""
                textAlignment="heading-left mb--20 mb_md--70"
                textColor=""
            />
                <div className="row">
                    <ServicePropOne colSize="col-lg-4" serviceStyle="service-style-2" itemShow="3" marginTop="yes"/>
                </div>
            </div>
            <ul className="shape-group-7 list-unstyled">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-2.png"} alt="circle" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="Line" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Line" /></li>
            </ul>

        </div>
                 <WhyChooseOne />


                <BrandOne />
                <AboutOne />
                <BlogOne />
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default PersonalPortfolio;

