import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BannerPosicionamientoGoogle from '../component/banner/BannerPosicionamientoGoogle';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import ProcessGrowth from '../component/process/ProcessGrowth';
import BrandOne from '../component/brand/BrandOne';
import AboutGrowth from '../component/about/AboutGrowth';
import AboutOne from '../component/about/AboutOne';
import BlogOne from '../component/blog/BlogOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import WhyChooseGrowth from '../component/whychoose/WhyChooseGrowth';
import ProjectOne from '../component/project/ProjectOne'
import WhyChoosePosicionamientoGoogle from '../component/whychoose/WhyChoosePosicionamientoGoogle';
import AnchorLink from 'react-anchor-link-smooth-scroll-v2';
import { Helmet } from 'react-helmet';


const AboutUs = () => {

    return (
        <>
        <SEO title="Posicionamiento Web en Google | Tráfico para tu Sitio " />
        <Helmet>
        <meta name="description" content=" En Merco Digital contamos con más de 10 años de experiencia en posicionamiento web en Google. ¡Alcanza los primeros resultados de búsqueda orgánica! " />
        </Helmet>

        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BannerPosicionamientoGoogle />
                <ProjectOne />
                <WhyChoosePosicionamientoGoogle />
                <BrandOne />
                <AboutOne />
                <BlogOne />
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default AboutUs;